<template>
  <b-container class="proposal-content mb-5 ml-3">
    <!-- Breadcrums -->
    <!-- <b-row>
      <b-col>
        <b-breadcrumb class="rprofile-breadcrumb">
          <b-breadcrumb-item>
            Mulle
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            meeldib
          </b-breadcrumb-item>
          <b-breadcrumb-item>P
            šnitsel
          </b-breadcrumb-item>
          <b-breadcrumb-item
            v-if="match.recruiter"
            active
          >
            {{ matchProfile.user.full_name }}
          </b-breadcrumb-item>
          <b-breadcrumb-item
            v-else
            active
          >
            {{ matchProfile.name }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-row> -->
    <b-row class="mt-4">
      <b-col
        lg="12"
        xl="6"
      >
        <CardBusiness
          v-if="($store.state.auth.selectedRecruiter || $store.state.auth.selectedAgency) && match"
          :match="match"
        />
        <RecruiterCard
          v-if="$store.state.auth.selectedBusiness && match"
          :match="match"
        />
      </b-col>
      <b-col
        lg="12"
        xl="6"
      >
        <PricingBlock
          v-if="match"
          :match="match"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <TermsBlock
          v-if="match"
          :match="match"
        />
      </b-col>
    </b-row>
    <b-row class="float-right mt-5 mb-3">
      <b-col>
        <b-btn
          class="mr-3"
          variant="ghost"
          @click="goBack"
        >
          Back to messages
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CardBusiness from '@/components/proposal/CardBusiness.vue'
import RecruiterCard from '@/components/proposal/RecruiterCard.vue'
import PricingBlock from '@/components/proposal/PricingBlock.vue'
import TermsBlock from '@/components/proposal/TermsBlock.vue'
import axios from '@/helpers/cleanAxios'

export default {
  name: 'ProposalView',
  components: {
    CardBusiness,
    RecruiterCard,
    PricingBlock,
    TermsBlock,
  },
  data () {
    return {
      match: null,
    }
  },
  created () {
    axios.get(`/v2/matches/${this.$route.params.id}`)
      .then(({ data }) => {
        this.match = data
        this.$store.commit('recruiter/SET_MATCH', data)
        this.$store.commit('agency/SET_MATCH', data)
        this.$store.commit('business/SET_MATCH', data)
      })
  },
  destroyed () {
    this.$store.commit('recruiter/SET_MATCH', null)
    this.$store.commit('agency/SET_MATCH', null)
    this.$store.commit('business/SET_MATCH', null)
  },
  methods: {
    goBack () {
      this.$router.push('/messages')
    },
  },
}
</script>

<style lang="scss" scoped>
.proposal-content {
  max-width: 74vw;
  .rprofile-breadcrumb  {
    background-color: transparent;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    .breadcrumb-item a{
        color: #39364F;
    }
    .breadcrumb-item.active {
        color: #EE8B7B;
    }
  }
}
</style>
