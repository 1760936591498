<template>
  <b-row class="info-block d-inline-block">
    <b-col class="mt-4">
      <b-card class="info-cards">
        <b-col class="h7 title">
          Additional information
        </b-col>
        <b-col
          v-if="match.proposal.message"
          class="h9 mt-2 mb-2 col-height"
        >
          {{ match.proposal.message }}
        </b-col>
        <b-col
          v-else
          class="h9 mt-2 text-center"
        >
          <b-img
            src="/images/no-references.svg"
            height="100px"
          />
          <br>
          <label class="h8 mt-2">
            No additional information added...
          </label>
        </b-col>
      </b-card>
    </b-col>
    <b-col class="mt-4">
      <b-card class="info-cards">
        <b-col class="h7 title">
          Attachment
        </b-col>
        <b-col
          v-if="match.proposal.attachments.length"
          class="file-grid h9 mt-2 mb-2 mt-2 grid-column col-height"
        >
          <a
            v-for="item in match.proposal.attachments"
            :key="item"
            :href="item"
            target="_blank"
            style="text-decoration: none;"
          >
            <br>
            <i
              class="ri-file-3-line ri-2x"
              style="vertical-align: text-top"
            />
            Attachment
            <i class="ri-share-box-line" />
          </a>
        </b-col>
        <b-col
          v-else
          class="h9 mt-2 mt-2 text-center"
        >
          <b-img
            src="/images/no-attachments.svg"
            height="100px"
          />
          <br>
          <label class="h8 mt-2">
            No attachments added...
          </label>
        </b-col>
      </b-card>
    </b-col>
    <b-col class="mt-4">
      <b-card class="info-cards">
        <b-col class="h7 title">
          Guarantees
        </b-col>
        <b-col
          v-if="match.proposal.default_guarantee || match.proposal.custom_guarantee"
          class="p-0 col-height"
        >
          <b-col
            v-if="match.proposal.default_guarantee"
            class="h9 mt-2 mb-2 mt-2 d-inline-flex"
          >
            <div class="mr-1">
              <i
                style="color: #00A725;"
                class="ri-checkbox-circle-line ri-xl"
              />
            </div>
            <span class="ml-1">
              If the hired candidate’s employment is terminated within the first <b>{{ match.proposal.default_guarantee }}</b> months of employment, a free replacement shall be provided.
            </span>
          </b-col>
          <b-col
            v-if="match.proposal.custom_guarantee"
            class="h9 mt-2 mb-2 mt-2 d-inline-flex"
          >
            <div class="mr-1">
              <i
                style="color: #00A725;"
                class="ri-checkbox-circle-line ri-xl"
              />
            </div>
            <span class="ml-1">
              {{ match.proposal.custom_guarantee }}
            </span>
          </b-col>
        </b-col>
        <b-col
          v-else
          class="h9 mt-2 mt-2 text-center"
        >
          <b-img
            src="/images/no-guarantees.svg"
            height="100px"
          />
          <br>
          <label class="h8 mt-2">
            No guarantees added...
          </label>
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'TermsBlock',
  props: {
    match: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      attachment: ['http://localhost:3333/uploads/files/8f32facf-07a8-4510-9e4b-8cc7eeb2a528.pdf'],
      guarantees: ['4', 'Suspendisse suscipit posuere nisi, at tempor ex. Vestibulum eget nibh augue. Ut sed neque in libero maximus dignissim sit amet ac metus. Vestibulum purus metus, pharetra in posuere vitae, auctor eu turpis. Morbi porttitor neque vitae nulla commodo, ut tempus mauris rutrum. Duis lobortis facilisis vulputate. Maecenas mattis orci eget faucibus mattis. Sed convallis ut ante ac faucibus. Nam dolor massa, iaculis at lectus et, condimentum blandit metus. Pellentesque viverra venenatis consequat. Nunc vulputate nec ex ac tempor.'],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet dui nec tortor scelerisque feugiat et eget mauris. Donec sit amet leo sed lacus vehicula pharetra. Sed ac interdum metus, sit amet fringilla augue. Fusce ultrices luctus orci. Nulla at condimentum tellus. Duis id velit venenatis, lobortis tortor eu, maximus nisi. Morbi nec eleifend dolor. Proin non congue risus. Suspendisse quis dolor ac magna ultricies convallis. Vestibulum luctus, justo nec fringilla fermentum, ligula orci tristique magna, eu faucibus nunc ipsum nec nulla. Aenean nec congue arcu, at condimentum nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet dui nec tortor scelerisque feugiat et eget mauris. Donec sit amet leo sed lacus vehicula pharetra. Sed ac interdum metus, sit amet fringilla augue. Fusce ultrices luctus orci. Nulla at condimentum tellus. Duis id velit venenatis, lobortis tortor eu, maximus nisi. Morbi nec eleifend dolor. Proin non congue risus. Suspendisse quis dolor ac magna ultricies convallis. Vestibulum luctus, justo nec fringilla fermentum, ligula orci tristique magna, eu faucibus nunc ipsum nec nulla. Aenean nec congue arcu, at condimentum nisi.',
    }
  },
}
</script>

<style lang="scss" scoped>
  .info-block {
    min-width: 74vw;
    .info-cards {
      background-color: #FFF;
      box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.5);
      border-radius: 12px;
      border: none;
    }
    .file-grid {
      display: grid;
    }
    .col-height {
      min-height: 120px;
    }
  }
</style>
