<template>
  <b-row class="ml-0 mr-0">
    <b-card class="top-profiles">
      <b-row>
        <b-col class="pr-0">
          <b-col
            v-if="match.proposal.fixed_fee"
            class="h7 text mt-2"
          >
            <label class="total-price mb-0 p-2">
              {{ match.proposal.currency }} {{ match.proposal.fixed_fee }}
            </label>
            <span class="ml-1">
              fixed fee
            </span>
            <i
              v-b-tooltip.hover
              class="ri-question-line ri-sm"
              title="Total amount"
              style="color: #605D9F;"
            />
          </b-col>
          <b-col
            v-if="match.proposal.hourly_rate"
            class="h7 text mt-2"
          >
            <label class="total-price mb-0 p-2">
              {{ match.proposal.currency }} {{ match.proposal.hourly_rate }}/h
            </label>
            <span>
              hourly fee
            </span>
          </b-col>
          <b-col
            v-if="match.proposal.contingency_fee"
            class="h7 text mt-2"
          >
            <label class="total-price mb-0 p-2">
              {{ match.proposal.currency }} {{ match.proposal.contingency_fee }}%
            </label>
            <span class="ml-1">
              contingency fee
            </span>
            <i
              v-b-tooltip.hover
              class="ri-question-line ri-sm"
              title="Total amount"
              style="color: #605D9F;"
            />
          </b-col>
          <b-col
            v-if="match.proposal.payments_frequency"
            class="p-0"
          >
            <b-col class="text-2 h9 mt-2">
              Paid in <b>{{ match.proposal.payments_frequency }}</b>
            </b-col>
            <b-col
              v-if="match.proposal.payments_frequency === '1 instalment'"
              class="text-2 h9 mt-1"
            >
              <label
                v-for="time in match.proposal.instalments"
                :key="time.name"
              >
                100% {{ time.payment_time }}
              </label>
            </b-col>
            <b-col
              v-if="match.proposal.payments_frequency === '2 instalments'"
              class="text-2 h9"
            >
              <label
                v-for="time in match.proposal.instalments"
                :key="time.name"
                class="mr-1"
              >
                {{ time.value }}% {{ time.payment_time }}
              </label>
            </b-col>
            <b-col
              v-if="match.proposal.payments_frequency === '3 instalments'"
              class="text-2 h9 mt-1"
            >
              <label
                v-for="time in match.proposal.instalments"
                :key="time.name"
                class="mr-1"
              >
                <b>{{ time.value }}%</b> {{ time.payment_time }}
              </label>
            </b-col>
          </b-col>
          <b-col
            v-if="match.proposal.contingency_fee"
            class="p-0"
          >
            <b-col class="text-2 h9 mt-4">
              For example, if the hire candidate’s annual salary is <b>{{ match.proposal.annual_salary }}{{ match.proposal.currency }}</b>, then the fee will be <b>{{ calculatedContingency }}{{ match.proposal.currency }}</b>
            </b-col>
            <b-col
              v-if="match.proposal.payments_frequency === '1 instalment'"
              class="text-2 h9 mt-1"
            >
              <label
                v-for="time in match.proposal.instalments"
                :key="time.name"
              >
                100% {{ time.payment_time }}
              </label>
            </b-col>
            <b-col
              v-if="match.proposal.payments_frequency === '2 instalments'"
              class="text-2 h9"
            >
              <label
                v-for="time in match.proposal.instalments"
                :key="time.name"
                class="mr-1"
              >
                {{ time.value }}% {{ time.payment_time }}
              </label>
            </b-col>
            <b-col
              v-if="match.proposal.payments_frequency === '3 instalments'"
              class="text-2 h9 mt-1"
            >
              <label
                v-for="time in match.proposal.instalments"
                :key="time.name"
                class="mr-1"
              >
                <b>{{ time.value }}%</b> {{ time.payment_time }}
              </label>
            </b-col>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
  </b-row>
</template>

<script>

export default {
  name: 'PricingBlock',
  props: {
    match: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    calculatedContingency () {
      let calculated = 0
      if (this.match.proposal.contingency_fee && this.match.proposal.annual_salary) {
        calculated = this.match.proposal.annual_salary * this.match.proposal.contingency_fee / 100
      }
      return calculated
    },
  },
}
</script>

<style lang="scss" scoped>
  .top-profiles {
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
    border: none;
    min-height: 147px;
    width: 100%;
    .text {
      font-size: 20px;
    }
    .text-2 {
      font-size: 12px;
    }
    .total-price {
      background: #E0FFE7;
      color: #00A725;
      border-radius: 8px;
    }
  }
</style>
